import React from 'react';
import * as Icon from 'react-feather';

// Cloud Hosting Services Images
import BookSelf from "../../assets/images/services-right-image/book-self.png";
import Box from "../../assets/images/services-right-image/box.png";
import Bordro from "../../assets/images/services-right-image/bordro.png";
import Chair from "../../assets/images/services-right-image/chair.png";
import TekTus from "../../assets/images/services-right-image/tektuslabordro.png";

import Cloud from "../../assets/images/services-right-image/cloud.png";
import Cup from "../../assets/images/services-right-image/cup.png";
import FlowerTop from "../../assets/images/services-right-image/flower-top.png";
import HeadPhone from "../../assets/images/services-right-image/head-phone.png";
import Monitor from "../../assets/images/services-right-image/monitor.png";
import Mug from "../../assets/images/services-right-image/mug.png";
import Table from "../../assets/images/services-right-image/table.png";
import Tissue from "../../assets/images/services-right-image/tissue.png";
import WaterBottle from "../../assets/images/services-right-image/water-bottle.png";
import Wifi from "../../assets/images/services-right-image/wifi.png";
import CercleShape from "../../assets/images/services-right-image/cercle-shape.png";
import ServiceRightMainPic from "../../assets/images/services-right-image/service-right-main-pic.png";

// Design & Development Images
import BigMonitor from "../../assets/images/services-left-image/big-monitor.png";
import Creative from "../../assets/images/services-left-image/creative.png";
import Developer from "../../assets/images/services-left-image/developer.png";
import SmallMonitor from "../../assets/images/services-left-image/small-monitor.png";
import SmallTop from "../../assets/images/services-left-image/small-top.png";
import Target from "../../assets/images/services-left-image/target.png";
import ServiceLeftMainPic from "../../assets/images/services-left-image/service-left-main-pic.png";
import GunlukAnaliz from "../../assets/images/services-left-image/gunluk-analiz.png";




const Ozel2 = () => {
    return (
        <>


            <div className="services-area ptb-80">
                <div className="container">
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 services-left-image">

                            <img
                                src={BigMonitor}
                                className="animate__animated animate__fadeInDown animate__delay-0.2s"
                                alt="big-monitor"
                            />


                            <img
                                src={GunlukAnaliz}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s"
                                alt="main-pic"
                            />
                            <img
                                src={Creative}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s"
                                alt="creative"
                            />
                            <img
                                src={CercleShape}
                                className="bg-image rotateme"
                                alt="shape"
                            />
                            <img
                                src={Developer}
                                className="animate__animated animate__fadeInLeft animate__delay-0.2s"
                                alt="developer"
                            />

                            <img
                                src={FlowerTop}
                                className="animate__animated animate__fadeInLeft animate__delay-0.2s"
                                data-wow-delay="0.6s"
                                alt="flower-top"
                            />

                            {/* <img 
                                src={SmallMonitor}
                                className="animate__animated animate__bounceIn animate__delay-0.2s" 
                                alt="small-monitor"
                            /> */}

                            {/* <img 
                                src={SmallTop}
                                className="animate__animated animate__fadeInDown animate__delay-0.2s" 
                                alt="small-top"
                            /> */}

                            {/* <img 
                                src={Table}
                                className="animate__animated animate__zoomIn animate__delay-0.2s" 
                                alt="table"
                            /> */}

                            {/* <img 
                                src={Target}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                alt="target"
                            /> */}



                        </div>

                        <div className="col-lg-6 col-md-12 services-content">
                            <div className="section-title">
                                <h2>Gelişmeye Devam Ediyor</h2>
                                <div className="bar"></div>
                                <p>Pera sizin için yeni teknolojiyi takip ediyor. Fabrika içi kameralardan yüz tanıma ve analiz üzerine ARGE çalışmaları yaptmaktadır</p>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Layout /> Son Teknoloji
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Code /> Yapay Zeka
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Smartphone /> Yüz Tanıma
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Code /> Görüntü İşleme
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Smartphone /> Yüz Tanıma
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.Percent /> Cad Çizimleri
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.ShoppingCart /> Big Data Analiz
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box">
                                        <Icon.CheckCircle /> Hızlı Erişim
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Ozel2;