import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

const OzelYazilim = () => {
    return (
        <div className="features-area pt-80 pb-50 bg-f9f6f6">
            <div className="container">
                <div className="section-title">
                    <h2>Çeşitli Firmalara Özel Yazılımlar</h2>
                    <div className="bar"></div>
                    <p>Firmalarda kriz olan veya geliştirmek istediğiniz bölümlere özel süreçleri analiz edip tamamen size özel yazılımlar yapıyoruz.  </p>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Settings />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Ücretsiz Analiz
                                </Link>
                            </h3>
                            <p>Firma içinde çözmek istediğiniz süreçleri ücretsiz analiz edip en doğru biçimde projelendiriyoruz</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Mail />
                            </div> 
                            <h3>
                                <Link to="/feature-details">
                                   İletişim Süreçleri
                                </Link>
                            </h3>
                            <p>Müşterilerinizle, bayilerinize, ekibinizle aranızdaki iletişimi güçlendirecek, firmanıza kurumsallık katacak özel projeler geliştiriyoruz</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-c679e3">
                                <Icon.Grid />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                   Fatura , Sipariş, İrsaliye, Muhasebe
                                </Link>
                            </h3>
                            <p>Muhasebe işlemlerinizi hızlandırıyor belirli bir disipline göre analiz ve giriş imkanı sağlıyoruz</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-c679e3">
                                <Icon.Info />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                 Randevu Takibi
                                </Link>
                            </h3>
                            <p>Hastane çeşitli kurum ve kuruluşlara özel randevu online görüşme gibi hizmetleri verebileceğiniz mobil uygulamaları Android ve İOS mağazalarda size özel hesaplar açarak yayınlıyor ve geliştiriyoruz</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-eb6b3d">
                                <Icon.Box />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Su,Ürün Siparişleri Mobil Uygulamalar
                                </Link>
                            </h3>
                            <p>Çeşitli illerde kullanılan kapıya gün içinde teslim edilen su bayileri, marketlere özel mobil uygulamalar geliştiriyoruz </p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon bg-eb6b3d">
                                <Icon.Bell />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Katalog Uygulamaları
                                </Link>
                            </h3>
                            <p>Firmanıza özel muhasebe programından beslenecek şekilde stoklarınızı katalog olarak dünyaya açıyoruz</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Settings />
                            </div>
                            <h3>
                                <Link to="/feature-details">
                                    Çağrı Merkezi Uygulamaları
                                </Link>
                            </h3>
                            <p>Santral ile ilişkili çağrı merkezi uygulamaları geliştiriyoruz</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-features">
                            <div className="icon">
                                <Icon.Mail />
                            </div> 
                            <h3>
                                <Link to="/feature-details">
                                   Performans Analizleri & Raporlar
                                </Link>
                            </h3>
                            <p>Personel bazlı veya aylık bazda izin mola çalışma performansı analizi yapar. Ayrıca tüm raporlara hızlıca ulaşabilirsiniz</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OzelYazilim;  